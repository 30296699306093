import React, { useState, useEffect } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faDiscord, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Form } from 'react-bootstrap';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.Trustpilot && typeof window.Trustpilot.loadFromElement === 'function') {
                clearInterval(interval);
                const elements = document.getElementsByClassName("trustpilot-widget");
                for (let i = 0; i < elements.length; i++) {
                    window.Trustpilot.loadFromElement(elements[i]);
                }
            }
        }, 100); 

        return () => clearInterval(interval); 
    }, []);

    const subscribeToNewsletter = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        console.log("Subscribing email:", email);
    };

    return (
        <div id="footer">
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4 className="h4">Our Newsletter</h4>
                            <p>Subscribe to our newsletter to keep yourself posted on the latest updates.</p>
                        </div>
                        <div className="col-lg-6">
                            <Form onSubmit={subscribeToNewsletter}>
                                <input type="email" name="email" placeholder="Enter your email" />
                                <input type="submit" value="Subscribe" />
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-top border-bottom">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4 className="h4">Useful Links</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/knowledgebase">Knowledgebase</a></li>
                                    <li><a href="/blog">Blog</a></li>
                                    <li><a href="/help">Support</a></li>
                                    <h4 className="h4 mb-0 mt-2">GAME HOSTING</h4>
                                    <li><a href="/services/gaming/minecraft">Minecraft</a></li>
                                    <li><a href="/services/gaming/sa-mp">SA-MP</a></li>
                                    <li><a href="/services/gaming/fivem">FiveM</a></li>

                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4 className="h4">CLOUD HOSTING</h4>
                                <ul>
                                    <li><a href="/services/servers">Linux VPS</a></li>
                                    <li><a href="/services/servers">Windows VPS</a></li>
                                    <li><a href="/features/oslist">Operating Systems</a></li>
                                    <li><a href="/features/locations">Locations</a></li>
                                    <li><a href="/features/controlpanel">Control Panel</a></li>
                                    <h4 className="h4 mt-2 mb-0">WEB HOSTING</h4>
                                    <li><a href="/services/shared-hosting">Shared Hosting</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-contact">
                                <h4 className="h4">Contact Us</h4>
                                <strong>Email:</strong> info@centnodes.com <br />
                                <h4 className="mt-3">More links</h4>
                                <div className="footer-links">
                                    <ul>
                                        <li><a href="#" onClick={(e) => { window.location.href = 'mailto:bugs@centnodes.com'; e.preventDefault(); }}>Report a bug</a></li>
                                        <li><a href="/tos">Terms of Use</a></li>
                                        <li><a href="/privacy">Privacy Policy</a></li>
                                        <li><a href="/gdpr">GDPR</a></li>
                                        <li><iframe title="status" src="https://status.centnodes.com/badge?theme=dark" width="250" height="30" frameBorder="0" scrolling="no"></iframe></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 footer-info">
                                <h3 className="h3">Centnodes</h3>
                                <p>Get your server up and running in under a minute. Whether it's a gaming server, VPS, or web hosting, we provide rapid deployment and exceptional support tailored to your needs.</p>

                                <div className="social-links mt-3">
                                    <a href="https://www.instagram.com/centnodes" target="_blank" rel="noopener noreferrer" className="instagram">
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </a>
                                    <a href="https://twitter.com/centnodes" target="_blank" rel="noopener noreferrer" className="twitter">
                                        <FontAwesomeIcon icon={faXTwitter} />
                                    </a>
                                    <a href="https://discord.gg/9yNxB4BTFE" target="_blank" rel="noopener noreferrer" className="discord">
                                        <FontAwesomeIcon icon={faDiscord} />
                                    </a>
                                    <a href="mailto:info@centnodes.com" target="_blank" rel="noopener noreferrer" className="email">
                                        <FontAwesomeIcon icon={faMailBulk} />
                                    </a>
                                </div>

                                <div onClick={() => window.open('https://nl.trustpilot.com/review/centnodes.com', "_blank")} className="trustpilot-widget mt-5 bg-white p-3" data-locale="en-EN" data-template-id="5419b6a8b0d04a076446a9ad" data-theme="light" data-businessunit-id="64a7254500ffdb1e3652c99d" data-style-height="24px" data-style-width="100%" data-min-review-count="0" data-style-alignment="center">
                                    <a href="https://nl.trustpilot.com/review/centnodes.com" target="_blank" rel="noopener">Trustpilot</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Centnodes</span></strong>. All Rights Reserved
                </div>
            </div>
        </div>
    );
};

export default Footer;
