import {
  faBoltLightning,
  faCheckCircle,
  faDesktopAlt,
  faFlag,
  faGear,
  faHeadset,
  faShoppingBasket,
  faUpload,
  faTools,
  faShield,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import { SiteNavigation } from "../../../components/Navigation/SiteNavigation";
import "./GamingServices.css";
import serverHeaderImage from "../../../images/design/samp-server-header.svg"; // Ensure you have a FiveM-specific header image
import fivemLogo from "../../../images/design/fivem-logo.png";

import { Helmet } from "react-helmet-async";

export const FivemServers = () => {
  useEffect(() => {
    // Any side effects or initializations can be added here
  }, []);

  return (
    <div>
      <Helmet>
        <title>Centnodes | FiveM Servers</title>
        <meta
          name="description"
          content="Launch a FiveM server in less than 5 minutes with txAdmin and robust anti-DDoS protection."
        />
      </Helmet>
      <SiteNavigation />
      <div className="border-bottom">
        <div className="container pb-3">
          <div className="row align-items-center mt-5">
            <div className="col-md-5 d-flex flex-column">
              <span className="h5">FiveM Servers</span>
              <span className="h2 mt-2">Your GTA V, Your Rules.</span>
              <p className="mt-2">
                Dive into the immersive world of GTA V with Centnodes' FiveM
                Server Hosting. Experience high-performance servers optimized
                for smooth, lag-free gameplay, enabling you to create and manage
                your custom multiplayer environment effortlessly. With our
                user-friendly txAdmin panel, robust anti-DDoS protection, and
                exceptional customer support, you can focus on building and
                enjoying your unique gaming experience.
              </p>
              <div className="btn-group">
                <a href="#packages" className="btn btn-primary rounded m-1">
                  See Packages
                </a>
                <a href="/help" className="btn btn-outline-primary rounded m-1">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-md-7 d-flex justify-content-center align-items-center mt-5 mt-xl-0">
              <div className="content-img">
                <img src={serverHeaderImage} alt="FiveM server" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-light p-4 text-center" id="plans">
        <div className="container d-flex mt-5 flex-column align-items-center">
          <h2 className="h2">FiveM Servers</h2>
          <p>
            Choose the ideal plan for your GTA V server and get started today
          </p>
        </div>
        <div className="products-package py-5 mt-5">
          <div className="container">
            <div className="row">
              {/* FiveM I Package */}
              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM I</h4>
                    <p className="mt-0">
                      A basic package to kickstart your journey in the FiveM
                      universe.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        1 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        15GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        2GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        1 MySQL Database
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        1 Free Subdomain
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      Free <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>

              {/* FiveM II Package */}
              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM II</h4>
                    <p className="mt-0">
                      For ambitious players striving to make their mark.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        2 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        25GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        4GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        2 MySQL Databases
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        2 Free Subdomains
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      &euro; 2,99 <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>

              {/* FiveM III to VI Packages */}
              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM III</h4>
                    <p className="mt-0">
                      For those seeking an enhanced gaming experience with
                      additional resources and control.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        2 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        50GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        8GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        3 MySQL Databases
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        3 Free Subdomains
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      &euro; 9,99 <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM IV</h4>
                    <p className="mt-0">
                      Ideal for server owners who require advanced
                      configurations and higher performance.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        3 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        50GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        12GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        4 MySQL Databases
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        4 Free Subdomains
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      &euro; 14,99 <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM V</h4>
                    <p className="mt-0">
                      Designed for professional server operators needing
                      extensive resources and top-tier performance.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        4 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        65GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        16GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        5 MySQL Databases
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        5 Free Subdomains
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      &euro; 19,99 <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card mb-5 border">
                  <div className="card-body text-center">
                    <img
                      className="img-fluid mb-4"
                      src={fivemLogo}
                      width="80"
                      alt="FiveM Logo"
                    />
                    <h4 className="mb-3">FiveM VI</h4>
                    <p className="mt-0">
                      The ultimate package for players aspiring to dominate the
                      FiveM world with maximum resources and support.
                    </p>

                    <ul className="mb-4 mt-2">
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        6 Core CPU
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        90GB SSD Storage
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        20GB RAM
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        6 MySQL Databases
                      </li>
                      <li>
                        <FontAwesomeIcon
                          color={"#51bbb5"}
                          icon={faCheckCircle}
                        />{" "}
                        6 Free Subdomains
                      </li>
                    </ul>
                    <h2 className="mb-4 h2">
                      &euro; 24,99 <span className="month">/ mo</span>
                    </h2>
                    <a
                      href="https://my.centnodes.com/products/gaming"
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faShoppingBasket} /> Order Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white border-top">
        <div className="container">
          <h4 className="h2 lead-title text-center">Why choose Centnodes?</h4>
          <p className="lead-text text-center">
            Below we list some advantages of choosing Centnodes for your gaming
            server
          </p>
          <div className="row gy-4 box text-start justify-content-center align-items-center">
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon
                  icon={faBoltLightning}
                  size={"2xl"}
                  className="lgg"
                />
                <span className="h5 mt-2">Performance</span>
                <span>
                  Experience seamless gameplay with our high-performance FiveM
                  servers, eliminating lag and downtime.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon icon={faGear} size={"2xl"} className="lgg" />
                <span className="h5 mt-2">Scalability</span>
                <span>
                  Whether you're a solo creator or running a massive multiplayer
                  server, our scalable solutions meet your needs.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon
                  icon={faDesktopAlt}
                  size={"2xl"}
                  className="lgg"
                />
                <span className="h5 mt-2">Control</span>
                <span>
                  Enjoy full control over your FiveM server with our easy-to-use
                  txAdmin control panel.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon icon={faUpload} size={"2xl"} className="lgg" />
                <span className="h5 mt-2">Uptime</span>
                <span>
                  Our reliable servers ensure your FiveM servers are accessible
                  24/7.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon
                  icon={faHeadset}
                  size={"2xl"}
                  className="lgg"
                />
                <span className="h5 mt-2">Support</span>
                <span>
                  Get instant help when you need it from our dedicated support
                  team.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon icon={faFlag} size={"2xl"} className="lgg" />
                <span className="h5 mt-2">Freedom</span>
                <span>
                  Create, explore, and dominate without limits in your FiveM
                  servers hosted by Centnodes.
                </span>
              </div>
            </div>
            {/* Adding new features */}
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  size={"2xl"}
                  className="lgg"
                />{" "}
                {/* Ensure you have faShieldAlt imported */}
                <span className="h5 mt-2">Anti-DDoS Protection</span>
                <span>
                  Protect your server from malicious attacks with our robust
                  anti-DDoS measures, ensuring uninterrupted gameplay.
                </span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column rounded p-3 text-center justify-content-center align-items-center border bg-light">
                <FontAwesomeIcon icon={faTools} size={"2xl"} className="lgg" />{" "}
                {/* Ensure you have faTools imported */}
                <span className="h5 mt-2">txAdmin Integration</span>
                <span>
                  Manage your FiveM server effortlessly with txAdmin, offering
                  powerful tools and an intuitive interface.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-label="Common questions" className="pt-5 border-bottom">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="text-left me-5">
                <span className="h2">Common Questions</span>
                <p className="mt-4">
                  To help you make an informed decision, we've compiled a list
                  of our frequently asked questions related to the services
                  listed on this page.
                </p>
                <p>
                  If you cannot find the answer to your question or would like
                  more information on the matter, you can visit our complete
                  Knowledgebase or contact us at any time and we will be happy
                  to assist you.
                </p>
                <div className="btn-groups">
                  <a
                    href="/knowledgebase"
                    className="btn btn-primary mt-1 mb-1"
                  >
                    Knowledgebase
                  </a>
                  <a
                    href="/help"
                    className="btn btn-outline-transparent mt-1 mb-1"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="text-left">
                <span className="h3">Do you have any doubts?</span>
                <p className="mt-3">
                  See if your question has already been answered in the list
                  below
                </p>
                <div
                  className="accordion accordion-flush"
                  id="accordionQuestion"
                >
                  <div className="accordion-item border mt-1 mb-1">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        How smooth is the gameplay on your FiveM servers?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionQuestion"
                    >
                      <div className="accordion-body">
                        Our servers are optimized to provide a seamless,
                        lag-free multiplayer experience in GTA V with FiveM.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border mt-1 mb-1">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Can your servers handle my growing needs?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionQuestion"
                    >
                      <div className="accordion-body">
                        Absolutely, whether your server size is small or large,
                        our scalable solutions can adapt to provide a seamless
                        gaming experience.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item border mt-1 mb-1">
                    <h2 className="accordion-header" id="flush-headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        What security measures are in place for my FiveM server?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionQuestion"
                    >
                      <div className="accordion-body">
                        We provide robust anti-DDoS protection to safeguard your
                        server from malicious attacks, ensuring uninterrupted
                        gameplay.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border mt-1 mb-1">
                    <h2 className="accordion-header" id="flush-headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        How do I manage my FiveM server?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionQuestion"
                    >
                      <div className="accordion-body">
                        Our integration with txAdmin allows you to manage your
                        FiveM server effortlessly with powerful tools and an
                        intuitive interface.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border mt-1 mb-1">
                    <h2 className="accordion-header" id="flush-headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        What is your server uptime?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionQuestion"
                    >
                      <div className="accordion-body">
                        Our servers offer excellent uptime, ensuring your FiveM
                        server is accessible 24/7.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
